import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { Modal } from 'antd';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id || null,
});

export const clearStore = () => {
  cache.restore({
    data: {
      auth: {},
    },
  });
};
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      switch (error?.extensions?.code) {
        case 'AUTH_NOT_AUTHORIZED':
          if (response) {
            const { pathname } = window.location;

            if (pathname !== '/login') {
              Modal.error({
                title: 'Dashboard',
                content: 'Thời gian đăng nhập đã hết hạn, vui lòng đăng nhập lại!',
                onOk: () => {
                  if (pathname !== '/login') {
                    window.location.replace('/login?callback=' + pathname);
                  }
                },
              });
            } else {
              response.errors = undefined;
              response.data = {};
            }
          }
          break;
        default:
          const message = error.extensions?.message || error.message;
          if (message) {
            Modal.error({
              title: 'Dashboard',
              content: message,
            });
          } else {
            Modal.error({
              title: 'Dashboard',
              content: 'Không thể kết nối tới API, Vui lòng liên hệ nhân viên hỗ trợ.',
            });
          }
          break;
      }
    }
  }

  if (networkError) {
    Modal.error({
      title: 'Dashboard',
      content: 'Không thể kết nối tới API, Vui lòng liên hệ nhân viên hỗ trợ.',
    });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const defaultLink = createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const wsTournamentLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPH_WS,
  options: {
    reconnect: true,
    connectionParams: () => {
      return {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
    },
  },
});
const appLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsTournamentLink,
  defaultLink,
);

const defaultClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(concat(authMiddleware, errorLink), appLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});
export default defaultClient;
