export default {
  'Copyright @ 2020 Zoominton': null,
  Tournaments: null,
  Sponsors: null,
  Stadiums: null,
  Clubs: null,
  Players: null,
  Dashboard: null,
  'System Users': null,
  'App Users': null,
  'Manage Users': null,
  Create: null,
  Roles: null,
  User: null,
  'Is Active': null,
  Actions: null,
  'Manage Tournament': null,
  'System Admin': null,
  'Tournament Sub Admin': null,
  Edit: null,
  Delete: null,
  'Create New User': null,
  UserName: null,
  Password: null,
  'Full Name': null,
  'Email Address': null,
  'Mobile No': null,
  Cancel: null,
  Save: null,
  'Manage Roles': null,
  Permissions: null,
  'Tournament Super Admin': null,
  'Tournament Sub Admin': null,
  'Tournaments Clubs': null,
  'Tournaments Management': null,
  'Tournaments Players': null,
  'Tournaments Sponsors': null,
  'Tournaments Stadiums': null,
  'Manage Tournaments': null,
  Name: null,
  Status: null,
  Highlight: null,
  'Start Date': null,
  'End Date': null,
  Settings: null,
  'Manage Sponsors': null,
  Address: null,
  'Manage Stadiums': null,
  Province: null,
  'Manage Clubs': null,
  'Personal Contact': null,
  'Manage Players': null,
  'Display Name': null,
  Club: null,
  Profile: null,
  Logout: null,
  'Date Of Birth': null,
  Avatar: null,
  Sponsor_Logo: null,
  Tournament_Images: null,
  News_Image: null,
  All: null,
  'User Profile': null,
  Search: null,
  Reset: null,
  Enabled: null,
  Disabled: null,
  Yes: null,
  No: null,
  New: null,
  'Open Register': null,
  'Close Register': null,
  Finished: null,
  Cancelled: null,
  Contains: null,
  'Ends with': null,
  'Starts with': null,
  'Create New Sponsor': null,
  'Are you sure disable highlight this tournament?': null,
  'Manage Formats': null,
  Age: null,
  'Competition Type': null,
  'Total Funding': null,
  'Format Type': null,
  Male: null,
  Female: null,
  'Double Male': null,
  'Double Female': null,
  'Male Female': null,
  'Expected Players': null,
  Description: null,
  View: null,
  Users: null,
  Formats: null,
  Others: null,
  Results: null,
  Highlights: null,
  News: null,
  Team_Confirm_Delete_Message: null,
  'Sample Import Excel File': null,
  'Import Excel': null,
  Successful: null,
  Error: null,
  Rules: null,
  'Short Description': null,
  'Is Highlight': null,
  'Highlight Order': null,
  Update: null,
  'File Upload': null,
  Upload: null,
  'Edit User': null,
  'Create New Tournament Sponsor': null,
  Position: null,
  Sponsor: null,
  Format: null,
  'Are you sure want delete this item?': null,
  'Edit Tournament Sponsor': null,
  'Please input the value': null,
  'Are you sure want to delete this player? We will set loose 0-2 for all the matches of this player!': null,
  'Add Player': null,
  'Invalid Form Values': null,
  'Please input mobile number or email!': null,
  'Add Tournament Player': null,
  'Update Tournament Player': null,
  Player: null,
  'Search Existing Players': null,
  'Create New Player': null,
  'Create New Format': null,
  'Edit Format': null,
  Stadium: null,
  'Age From': null,
  'Age To': null,
  'Manage Format': null,
  Rounds: null,
  Teams: null,
  'Create Round': null,
  Matches: null,
  'Create Group': null,
  'Edit Tournament Round': null,
  'Edit Tournament Group': null,
  'Create New Tournament Round': null,
  'Manage Matches': null,
  'Not Start': null,
  'In Progress': null,
  InProgress: null,
  Completed: null,
  Pausing: null,
  Game: null,
  'Edit Match': null,
  Referee: null,
  'Have Commentary': null,
  Duration: null,
  'Manage Game Scores': null,
  'Serving Player': null,
  'Receive Player': null,
  'Game Number': null,
  'Create New Game Score': null,
  'Serving Team': null,
  'Edit Game Scores': null,
  'Can not select same player in a team!': null,
  'Can not select same team in the match!': null,
  Code: null,
  'Other Rules': null,
  Handed: null,
  'Search Clubs': null,
  'Registration Opening': null,
  'Registration Deadline': null,
  'Update Roles': null,
  Approved: null,
  Registered: null,
  Rejected: null,
  'Team Name': null,
  'Create New Team': null,
  'Select Player': null,
  'Edit Team': null,
  'Create New Match': null,
  Team: null,
  'Team 2 Score': null,
  'Team 1 Score': null,
  'Manage Results': null,
  'Create New Tournament Result': null,
  Order: null,
  Reward: null,
  'Edit Tournament Result': null,
  'Manage News': null,
  'Create New Club': null,
  'Edit Club': null,
  'Contact Name': null,
  'Create New Stadium': null,
  Town: null,
  District: null,
  'Edit Stadium': null,
  'Edit Sponsor': null,
  'Create New Role': null,
  'Update Permissions': null,
  'All Matches': null,
  Title: null,
  Creator: null,
  Approver: null,
  'Create New Tournament New': null,
  Summary: null,
  Content: null,
  Details: null,
  'Add Existing User': null,
  Approve: null,
  Reviewing: null,
  'Edit Tournament New': null,
  'Highlight Matches': null,
  true: null,
  false: null,
  'User Name': null,
  'Manage User Profile': null,
  'Basic Infos': null,
  Gender: null,
  Height: null,
  Location: null,
  'Basic Infos Privacy': null,
  'Only Me': null,
  Public: null,
  Friends: null,
  'Contact Infos': null,
  'Contact Privacy': null,
  'Rewards Privacy': null,
  Reset: null,
  'Select a format': null,
  'Select a round': null,
  Round: null,
  Group: null,
  'Select a group': null,
  'Edit Role': null,
  'Organization Board': null,
  'Tournament Name': null,
  'Sponsor Name': null,
  'Sponsor Description': null,
  'Format Name': null,
  'Total Format Funding': null,
  'Format Expected Players': null,
  'Results Name': null,
  'Short Summary': null,
  'News Content': null,
  'Player Name': null,
  Tournament_Update_Successful: 'Update Tournament Successful!',
  Tournament_UpdateMatch_Error:
    'Some matches are not completed yet! Please make sure all the matches complete before make tournament complete.',
  'This method required at least 1 field change': null,
  TournamentRound_Null_TournamentFormat: 'Tournament format selected doest not exist.',
  TournamentRound_Name_Already_Exist: 'Round name already exist!',
  TournamentGroup_Name_Already_Exist: 'Group name already exist!',
  ImportExcel_NoOfSheet_Invalid: 'Number of Excel Sheets are not correct.',
  ImportExcel_File_Not_Found: 'No Excel file found.',
  ImportExcel_Import_Successful: 'Successful!',
  ImportExcel_Import_Failed: 'Failed!',
  TournamentMatch_Update_Successful: 'Update Match Successful!',

  User_Update_UserProfile_Successful: 'Update User Profile Successful!',
  CANCELLED: 'Cancelled',
  FINISHED: 'Finished',
  IN_PROGRESS: 'In Pprogress',
  NOT_SCHEDULED: 'Not Scheduled',
  PAUSING: 'Pausing',
  SCHEDULED: 'Scheduled',
  Tournament: null,
  Entries: null,
  Events: null,
  Courts: null,
  Days: null,
  Scheduled: null,
  Date: null,
  'Pick a Task': null,
  'Court Usage': null,
  Statistics: null,
  Event: null,
  Entries: null,
  Excluded: null,
  Entered: null,
  Matches: null,
  'Not Scheduled': null,
  Walkovers: null,
  Games: null,
  Point: null,
  Minutes: null,
  Avg: null,
  Draws: null,
  Seed: null,
  State: null,
  Country: null,
  Playoff: null,
  Stage: null,
  'Add Draw': null,
  'Columns Config': null,
  'Check All': null,
  Score: null,
  Warned: null,
  Note: null,
  Message: null,
  Time: null,
  Roster: null,
  Notes: null,
  Messages: null,
  Overview: null,
  ANY_GENDER: 'Any ender',
  ANY_GENDERS: 'Any genders',
  DOUBLE_FEMALE: 'Double Female',
  DOUBLE_MALE: 'Dounle Male',
  FEMALE: 'Female',
  MALE: 'Male',
  MIXED: 'Mixed',
  'Please select rules for scoring and standings calculation.': null,
  'Above row of rules for standings calculation will be higher priority.': null,
  Notification: 'Notification',
};
