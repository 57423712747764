import React, { Component } from 'react';

export interface AppContextInterface {
  collapsedSidebar: boolean;
  authLoaded: boolean;
  auth: any;
  onUpdateContext: (...params: any) => void;
  onResetContext: (...params: any) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  collapsedSidebar: false,
  authLoaded: false,
  auth: null,
  onUpdateContext: (context) => context,
  onResetContext: (context) => context,
});

export class AppProvider extends Component {
  onUpdateContext = (context) => {
    const newContext = { ...this.state, ...context };
    this.setState(newContext);
  };

  onResetContext = () => {
    this.setState({
      authLoaded: true,
      auth: null,
    });
  };

  state = {
    collapsedSidebar: false,
    authLoaded: false,
    auth: null,
    onUpdateContext: this.onUpdateContext,
    onResetContext: this.onResetContext,
  };

  render() {
    return <AppContext.Provider value={{ ...this.state }}>{this.props.children}</AppContext.Provider>;
  }
}

export default AppProvider;
