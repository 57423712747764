import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './Layout';
import apolloClient from './apollo';
import history from './history';
import AppProvider from './contexts/AppContext';
import '@/helpers/i18n';
import './assets/less/app.less';

async function getCommonData(): Promise<any> {
  // First data calling here
  return true;
}

const renderApp = () => {
  render(
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <Router history={history}>
          <App />
        </Router>
      </AppProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );
};

getCommonData()
  .then(() => renderApp())
  .catch((e) => console.error(e));
