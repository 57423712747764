import React from 'react';
import { Route } from 'react-router-dom';

const NoLayoutRoute = ({ component: Component = null, path, children = null, user = null, ...rest }) => {
  if (children) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }
  return <Route path={path} {...rest} render={(props) => <Component {...props} />} />;
};

export default NoLayoutRoute;
