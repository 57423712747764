export default {
  'Copyright @ 2020 Zoominton': 'Bản quyền thuộc Zoominton /2020',
  Tournaments: 'Giải đấu',
  Sponsors: 'Nhà tài trợ',
  Stadiums: 'Nhà thi đấu',
  Clubs: 'Câu lạc bộ',
  Players: 'Vận động viên',
  Dashboard: 'Trang chủ',
  'System Users': 'Tài khoản hệ thống',
  'App Users': 'Tài khoản Zoominton',
  'Manage Users': 'Quản lý tài khoản',
  Create: 'Thêm mới',
  Roles: 'Vai trò',
  User: 'Tài khoản',
  'Is Active': 'Đang hoạt động',
  Actions: 'Hoạt động',
  'Manage Tournament': 'Quản lý giải đấu',
  'System Admin': 'Quản lý hệ thống',
  'Tournament Sub Admin': 'Quản lý giải đấu',
  Edit: 'Sửa',
  Delete: 'Xóa',
  'Create New User': 'Thêm mới tài khoản',
  UserName: 'Tài khoản',
  Password: 'Mật khẩu',
  'Full Name': 'Họ và tên',
  'Email Address': 'Email',
  'Mobile No': 'Số điện thoại',
  Cancel: 'Hủy',
  Save: 'Lưu',
  'Manage Roles': 'Quản lý vai trò',
  Permissions: 'Quyền hạn',
  'Tournament Super Admin': 'Tournament Super Admin',
  'Tournaments Clubs': 'Câu lạc bộ',
  'Tournaments Management': 'Quản lý giải đấu',
  'Tournaments Players': 'Vận động viên',
  'Tournaments Sponsors': 'Nhà tài trợ',
  'Tournaments Stadiums': 'Nhà thi đấu',
  'Manage Tournaments': 'Quản lý các giải đấu',
  Name: 'Tên',
  Status: 'Trạng thái',
  Highlight: 'Nổi bật',
  'Start Date': 'Ngày bắt đầu',
  'End Date': 'Ngày kết thúc',
  Settings: 'Cài đặt',
  'Manage Sponsors': 'Quản lý nhà tài trợ',
  Address: 'Địa chỉ',
  'Manage Stadiums': 'Quản lý nhà thi đấu',
  Province: 'Tỉnh',
  'Manage Clubs': 'Quản lý câu lạc bộ',
  'Personal Contact': 'Người đại diện',
  'Manage Players': 'Quản lý vận động viên',
  'Display Name': 'Tên hiển thị',
  Club: 'Câu lạc bộ',
  Profile: 'Thông tin cá nhân',
  Logout: 'Đăng xuất',
  'Date Of Birth': 'Ngày sinh',
  Avatar: 'Ảnh đại diện',
  Sponsor_Logo: 'Logo',
  Tournament_Images: 'Ảnh giải đấu',
  News_Image: 'Ảnh bài viết',
  All: 'Tất cả',
  'User Profile': 'Thông tin tài khoản',
  Search: 'Tìm kiếm',
  Reset: 'Reset',
  Enabled: 'Bật',
  Disabled: 'Tắt',
  Yes: 'Có',
  No: 'Không',
  New: 'Mới',
  'Open Register': 'Mở đăng ký',
  'Close Register': 'Đóng đăng ký',
  Finished: 'Kết thúc',
  Cancelled: 'Đã hủy',
  Contains: 'Bao gồm',
  'Ends with': 'Kết thúc với',
  'Starts with': 'Bắt đầu với',
  'Create New Sponsor': 'Nhà tài trợ mới',
  'Are you sure disable highlight this tournament?': 'Bạn chắc chắn tắt nổi bật cho giải đấu này?',
  'Manage Formats': 'Quản lý nội dung thi đấu',
  Age: 'Tuổi',
  'Competition Type': 'Thể thức',
  'Total Funding': 'Tổng giải thưởng giải đấu',
  'Format Type': 'Nội dung thi đấu',
  Male: 'Nam',
  Female: 'Nữ',
  'Double Male': 'Đôi nam',
  'Double Female': 'Đôi nữ',
  'Male Female': 'Đôi nam nữ',
  'Expected Players': 'Số VĐV dự kiến',
  Description: 'Chi tiết',
  View: 'Xem',
  Users: 'Tài khoản',
  Formats: 'Nội dung thi đấu',
  Others: 'Khác',
  Results: 'Kết quả',
  Highlights: 'Nổi bật',
  News: 'Tin tức',
  Team_Confirm_Delete_Message:
    'Bạn có chắc chắn muốn xóa team này không? Hệ thống sẽ xóa toàn bộ các trận đấu của team này.',
  'Sample Import Excel File': 'File Excel mẫu',
  'Import Excel': 'Nhập file Excel',
  Successful: 'Thành công',
  Error: 'Lỗi',
  Rules: 'Thể lệ',
  'Short Description': 'Tóm tắt',
  'Is Highlight': 'Nổi bật',
  'Highlight Order': 'Thứ tự nổi bật',
  Update: 'Cập nhật',
  'File Upload': 'Tải File',
  Upload: 'Tải ảnh',
  'Edit User': 'Cập nhật tài khoản',
  'Create New Tournament Sponsor': 'Thêm nhà tài trợ mới',
  Position: 'Vị trí',
  Sponsor: 'Nhà tài trợ',
  Format: 'Nội dung thi đấu',
  'Are you sure want delete this item?': 'Bạn có chắc chắn muốn xóa mục này không?',
  'Edit Tournament Sponsor': 'Sửa nhà tài trợ giải đấu',
  'Please input the value': 'Vui lòng nhập giá trị',
  'Are you sure want to delete this player? We will set loose 0-2 for all the matches of this player!':
    'Bạn có chắc muốn xóa Vận động viên này? Hệ thống sẽ tự động thêm tỉ số thua 0-2 cho tất cả trận đấu của Vận động viên này!',
  'Add Player': 'Thêm vận động viên',
  'Invalid Form Values': 'Giá trị đăng ký không hợp lệ',
  'Please input mobile number or email!': 'Vui lòng nhập số điện thoại hoặc email!',
  'Add Tournament Player': 'Thêm vận động viên',
  'Update Tournament Player': 'Sửa thông tin vận động viên',
  Player: 'Vận động viên',
  'Search Existing Players': 'Tìm vận động viên',
  'Create New Player': 'Thêm vận động viên mới',
  'Create New Event': 'Thêm nội dung thi đấu',
  'Edit Format': 'Sửa thể thức thi đấu',
  Stadium: 'Nhà thi đấu',
  'Age From': 'Tuổi tối thiểu',
  'Age To': 'Tuổi tối đa',
  'Manage Format': 'Quản lý thể thức thi đấu',
  Rounds: 'Các thể thức thi đấu',
  Teams: 'Các team',
  'Create Round': 'Tạo vòng đấu',
  'Create Group': 'Tạo bảng',
  'Edit Tournament Round': 'Sửa vòng đấu',
  'Edit Tournament Group': 'Sửa thế thức thi đấu',
  'Create New Tournament Round': 'Tạo vòng đấu mới',
  'Manage Matches': 'Quản lý các trận đấu',
  'Not Start': 'Chưa bắt đầu',
  'In Progress': 'Đang diễn ra',
  InProgress: 'Đang diễn ra',
  Completed: 'Hoàn thành',
  Pausing: 'Tạm dừng',
  Game: 'Set',
  'Edit Match': 'Sửa trận đấu',
  Referee: 'Trọng tài',
  'Have Commentary': 'Có bình luận',
  Duration: 'Thời lượng',
  'Manage Game Scores': 'Quản lý điểm số',
  'Serving Player': 'Người giao cầu',
  'Receive Player': 'Người nhận cầu',
  'Game Number': 'Set',
  'Create New Game Score': 'Tạo quản lý điểm',
  'Serving Team': 'Bên giao cầu',
  'Edit Game Scores': 'Sửa điểm số',
  'Can not select same player in a team!': 'Không thể chọn 2 vận động viên giống nhau trong cùng một team!',
  'Can not select same team in the match!': 'Không thể chọn 2 team giống nhau trong cùng một trận đấu!',
  Code: 'Mã trận',
  'Other Rules': 'Yêu cầu khác',
  Handed: 'Tay thuận',
  'Search Clubs': 'Tìm kiếm câu lạc bộ',
  'Registration Opening': 'Ngày bắt đầu đăng ký',
  'Registration Deadline': 'Ngày kết thúc đăng ký',
  'Update Roles': 'Sửa vai trò',
  Approved: 'Đã phê duyệt',
  Registered: 'Đã đăng ký',
  Rejected: 'Từ chối',
  'Team Name': 'Tên nhóm',
  'Create New Team': 'Lập nhóm mới',
  'Select Player': 'Chọn vận động viên',
  'Edit Team': 'Sửa nhóm',
  'Create New Match': 'Khởi tạo trận đấu mới',
  Team: 'Nhóm',
  'Team 2 Score': 'Điểm số nhóm 2',
  'Team 1 Score': 'Điểm số nhóm 1',
  'Manage Results': 'Quản lý kết quả',
  'Create New Tournament Result': 'Thêm mới kết quả giải đấu',
  Order: 'Thứ tự',
  Reward: 'Phần thưởng',
  'Edit Tournament Result': 'Thay đổi kết quả giải đấu',
  'Manage News': 'Quản lý tin tức',
  'Create New Club': 'Thêm câu lạc bộ',
  'Edit Club': 'Sửa câu lạc bộ',
  'Contact Name': 'Người liên lạc',
  'Create New Stadium': 'Thêm nhà thi đấu',
  Town: 'Phường/Xã',
  District: 'Quận/Huyện',
  'Edit Stadium': 'Sửa nhà thi đấu',
  'Edit Sponsor': 'Sửa nhà tài trợ',
  'Create New Role': 'Thêm vai trò mới',
  'Update Permissions': 'Sửa quyền',
  'All Matches': 'Tất cả các trận đấu',
  Title: 'Tiêu đề',
  Creator: 'Người tạo',
  Approver: 'Người phê duyệt',
  'Create New Tournament New': 'Tạo bài viết mới',
  Summary: 'Tổng kết',
  Content: 'Nội dung',
  Details: 'Chi tiết',
  'Add Existing User': 'Thêm tài khoản có sẵn',
  Approve: 'Xác nhận',
  Reviewing: 'Đang phê duyệt',
  'Edit Tournament New': 'Sửa tin tức giải đấu',
  'Highlight Matches': 'Các trận đấu nổi bật',
  true: 'có',
  false: 'không',
  'User Name': 'Tên tài khoản',
  'Manage User Profile': 'Quản lý thông tin tài khoản',
  'Basic Infos': 'Thông tin cơ bản',
  Gender: 'Giới tính',
  Height: 'Chiều cao',
  Location: 'Địa chỉ',
  'Basic Infos Privacy': 'Thông tin cơ bản riêng tư',
  'Only Me': 'Chỉ mình tôi',
  Public: 'Công khai',
  Friends: 'Bạn bè',
  'Contact Infos': 'Thông tin liên lạc',
  'Contact Privacy': 'Thông tin liên lạc riêng tư',
  'Rewards Privacy': 'Thành tích riêng tư',
  Reset: 'Làm mới',
  'Select a format': 'Nội dung thi đấu',
  'Select a round': 'Vòng đấu',
  Round: 'Vòng đấu',
  Group: 'Bảng đấu',
  'Select a group': 'Bảng đấu',
  'Edit Role': 'Sửa vai trò',
  'Organization Board': 'Đơn vị tổ chức',
  'Tournament Name': 'Tên giải đấu',
  'Sponsor Name': 'Nhà tài trợ',
  'Sponsor Description': 'Chi tiết tài trợ',
  'Format Name': 'Tên nội dung thi đấu',
  'Total Format Funding': 'Tổng giải thưởng của nội dung thi đấu',
  'Format Expected Players': 'Số VĐV dự kiến tham gia nội dung thi đấu',
  'Results Name': 'Tên giải thưởng',
  'Short Summary': 'Mô tả ngắn',
  'News Content': 'Nội dung bài viết',
  'Player Name': 'Tên vận động viên',
  Tournament_Update_Successful: 'Cập nhật thông tin giải đấu thành công!',
  Tournament_UpdateMatch_Error:
    'Một số trận đấu của giải đấu chưa ở trạng thái kết thúc! Vui lòng kết thúc toàn bộ các trận đấu trước khi kết thúc giải đấu',
  'This method required at least 1 field change': 'Không có thay đổi nào được gửi lên.',
  TournamentRound_Null_TournamentFormat: 'Nội dung thi đấu đã chọn không tồn tại.',
  TournamentRound_Name_Already_Exist: 'Vòng đấu đã tồn tại!',
  TournamentGroup_Name_Already_Exist: 'Bảng đấu đã tồn tại!',
  ImportExcel_NoOfSheet_Invalid: 'Sô lượng bảng (sheet) trong file excel không đúng.',
  ImportExcel_File_Not_Found: 'Không tìm thấy file upload.',
  ImportExcel_Import_Successful: 'thành công!',
  ImportExcel_Import_Failed: 'không thành công!',
  TournamentMatch_Update_Successful: 'Cập nhật thông tin trận đấu thành công!',
  User_Update_UserProfile_Successful: 'Cập nhật thông tin user thành công!',
  Tournament: 'Giải đấu',
  Entries: 'Báo danh',
  Events: 'Nội dung thi đấu',
  Courts: 'Số sân',
  Days: 'Số ngày',
  Scheduled: 'Đã xếp lịch',
  Date: 'Ngày',
  'Pick a Task': 'Chọn tính năng',
  'Court Usage': 'Sân đấu',
  Statistics: 'Thống kê',
  Event: 'Nội dung',
  Entries: 'Số VĐV',
  Excluded: 'Bỏ giải',
  Entered: 'Tham gia',
  Matches: 'Trận đấu',
  'Not Scheduled': 'Chưa lên lịch',
  Walkovers: 'Đã thi đấu',
  Games: 'Số set',
  Point: 'Số trận đấu',
  Minutes: 'Số phút',
  Avg: 'Thời gian trung bình',
  Draws: 'Bảng',
  Seed: 'Hạt giống',
  State: 'Bang/Vùng',
  Country: 'Quốc gia',
  Playoff: 'Vòng loại',
  Stage: 'Vòng',
  'Add Draw': 'Thêm bảng',
  'Columns Config': 'Cấu hình',
  'Check All': 'Tất cả',
  Score: 'Tỉ số',
  Warned: 'Thông báo',
  Note: 'Chú ý',
  Message: 'Tin nhắn',
  Time: 'Thời gian',
  Roster: 'Chia bảng',
  Notes: 'Ghi chú',
  Messages: 'Tin nhắn',
  Overview: 'Tổng quan',
  CANCELLED: 'Đã huỷ',
  FINISHED: 'Kết thúc',
  IN_PROGRESS: 'Đang diễn ra',
  NOT_SCHEDULED: 'Chưa lên lịch',
  PAUSING: 'Tạm Hoãn',
  SCHEDULED: 'Đã lên lịch',
  'Size Of Main Draw': 'Số đội thi đấu',
  'Size Of Next Draw': 'Số đội chọn vào vòng tiếp theo',
  Qualification: 'Vòng loại',
  Type: 'Loại',
  ELIMINATION: 'Loại trực tiếp',
  ROUNDROBIN: 'Đấu vòng tròn',
  'Age range': 'Độ tuổi',
  ANY_GENDER: 'Đơn (Nam hoặc Nữ)',
  ANY_GENDERS: 'Đôi (Nam hoặc Nữ)',
  DOUBLE_FEMALE: 'Đôi Nữ',
  DOUBLE_MALE: 'Đôi Nam',
  FEMALE: 'Đơn Nữ',
  MALE: 'Đơn Nam',
  MIXED: 'Nam nữ kết hợp',
  'Result Score': 'Điểm',
  Rank: 'Xếp hạng',
  'Win Matches': 'Trận thắng',
  'Lose Matches': 'Trận thua',
  'Win Games': 'Game thắng',
  'Lose Games': 'Game thua',
  'Win Points': 'Điểm thắng',
  'Lose Points': 'Điểm thua',
  MATCHPOINTS: 'Điểm',
  POINTDIFFERENCE: 'Hiệu số điểm của set',
  HEADTOHEAD: 'Thành tích đối đầu',
  GAMEDIFFERENCE: 'Hiệu số set',
  'Match Won Point': 'Điểm mỗi trận thắng',
  'Match Lost Point': 'Điểm mỗi trận thua',
  'Standings Calculation Rules': 'Quy tắc xếp hạng',
  'Tournament Organizer': 'Tên ban tổ chức',
  'Organizer Phone': 'SĐT ban tổ chức',
  'Organizer Email': 'Email',
  'Please select rules for scoring and standings calculation.':
    'Chọn cách tính điểm và tiêu chí ưu tiên xếp hạng khi thi đấu vòng tròn.',
  'Above row of rules for standings calculation will be higher priority.':
    'Tiêu chí nào được xếp trên sẽ được ưu tiên hơn khi xếp hạng VDV/Đội.',
  'First Name': 'Họ',
  'Middle Name': 'Tên đệm',
  'Last Name': 'Tên',
  MATCH_REFEREE: 'Trọng tài',
  TV_CONTROL: 'Điều khiển TV',
  RIGHT_HAND: 'Tay phải',
  LEFT_HAND: 'Tay trái',
  'Event Result': 'Xếp hạng',
  'Created At': 'Ngày tạo',
  'Create Tournament': 'Tạo mới giải đấu',
  Birthday: 'Ngày sinh',
  'Highlight Url': 'Video nổi bật',
  'Winner Team': 'Đội thắng',
  Unknown: 'Chưa biết',
  Draw: 'Vòng',
  'Export Excel': 'Xuất file Excel',
  'Export PDF': 'Xuất file PDF',
  'Roster - Main Draw': 'Chia bảng thi đấu',
  'From Date': 'Từ ngày',
  'To Date': 'Tới ngày',
  'Manage Events': 'Quản lý nội dung thi đấu',
  CLOSE_REGISTER: 'Đóng đăng ký',
  FINISHED: 'Kết thúc',
  IN_PROGRESS: 'Đang diễn ra',
  NEW: 'Mới',
  OPEN_REGISTER: 'Mở đăng ký',
  'Export players to Excel': 'Xuất dữ liệu VĐV (Excel)',
  'Export players to PDF': 'Xuất dữ liệu VĐV (PDF)',
  'Export players by club to Excel': 'Xuất dữ liệu VĐV theo CLB (Excel)',
  'Export players by club to PDF': 'Xuất dữ liệu VĐV theo CLB (PDF)',
  'Download Templates': 'Tải file mẫu',
  'Player template (EN version)': 'File mẫu VĐV (Bản tiếng anh)',
  'Player template (VN version)': 'File mẫu VĐV (Bản tiếng Việt)',
  'Export Clubs to Excel': 'Xuất dữ liệu CLB (Excel)',
  'Export Clubs to PDF': 'Xuất dữ liệu CLB (PDF)',
  'Export Results to Excel': 'Kết quả (Excel)',
  'Export Results to PDF': 'Kết quả (PDF)',
  Report: 'Báo cáo',
  'Import Players (EN version)': 'Nhập dữ liệu VĐV (Bản Tiếng Anh)',
  'Import Players (VN version)': 'Nhập dữ liệu VĐV (Bản Tiếng Việt)',
  'Edit Player': 'Thay đổi thông tin VĐV',
  'Add New Player': 'Thêm mới VĐV',
  'Round Robins': 'Thi đấu vòng tròn',
  'Official Account': 'Tài khoản giải đấu',
  'Add Account': 'Thêm tài khoản',
  'Add New Account': 'Thêm mới tài khoản',
  DOB: 'Ngày sinh',
  Quarterfinals: 'Tứ kết',
  Semifinals: 'Bán kết',
  Final: 'Chung kết',
  Result: 'Kết quả',
  Notification: 'Thông báo',
  WAIT: 'Chờ thực thi',
  COMPLETED: 'Hoàn thành',
  CANCEL: 'Bị huỷ',
  ERROR: 'Lỗi',
  'Execution Date': 'Giờ thực thi',
  'Updated At': 'Giờ cập nhật',
  Total: 'Tổng',
  Success: 'Thành công',
  Failure: 'Thất bại',
  'Add New Notification': 'Thêm mới thông báo',
  'Update Infomation': 'Cập nhật thông tin',
  CUSTOM: 'Tự tạo',
  TOURNAMENT_CANCELLED: 'Huỷ giải',
  TOURNAMENT_CLOSE_REGISTER: 'Đóng đăng ký',
  TOURNAMENT_CREATE_NEW: 'Tạo mới giải',
  TOURNAMENT_FINISHED: 'Kết thúc',
  TOURNAMENT_OPEN_REGISTER: 'Mở đăng ký',
  TOURNAMENT_DISABLED: 'Giải tạm dừng',
  TOURNAMENT_INPROGRESS: 'Đang diễn ra',
  TOURNAMENT_EVENT_HAS_SCHEDULED: 'Có lịch thi đấu',
  TOURNAMENT_MATCH_START: 'TĐ bắt đầu',
  'Manage Notification': 'Quản lý thông báo',
  Court: 'Sân',
  'Phone Number': 'Số điện thoại',
  Level: 'Cấp độ',
};
