import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PageLoading from '../components/PageLoading';
import NoLayoutRoute from '../components/routes/NoLayoutRoute';

const LoginPage = lazy(() => import('../pages/Auth/LoginPage'));
const TournamentLayout = lazy(() => import('./tournament'));
const SystemLayout = lazy(() => import('./system'));
const TVLayout = lazy(() => import('./tv'));

export default function renderRoutes() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path="/tournament-management" component={TournamentLayout} />
        <Route path="/system" component={SystemLayout} />
        <Route path="/tv" component={TVLayout} />
        <NoLayoutRoute exact path={'/login'} component={LoginPage} />
        <Redirect from="/" to="/tournament-management" />
        <NoLayoutRoute exact path="*">
          <React.Suspense fallback={<PageLoading />}>
            <h1>404</h1>
          </React.Suspense>
        </NoLayoutRoute>
      </Switch>
    </Suspense>
  );
}
